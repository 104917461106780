import Axios from 'axios';
import {
  PatientAddInterface,
  PatientDemogrphicUpdateInterface,
  PatientFetchByIdInterface,
  PatientFetchInterface,
} from '../../interfaces/patientInterface';
import { authenticatedRequest } from '../../utils/commonAxios';

export const getPatientsAPIRequest = async (searchParams: PatientFetchInterface) => {
  return await authenticatedRequest(
    `${process.env.REACT_APP_API_URL}/v1/patient/search?page=${searchParams?.page}&size=${searchParams?.size}`,
    'post',
    {
      data: searchParams?.filters ?? {},
    }
  );
};

export const addPatientAPIRequest = async (userData: PatientAddInterface) => {
  // console.log('== userData ==', userData);

  return await authenticatedRequest(`${process.env.REACT_APP_API_URL}/v1/patient/`, 'post', {
    data: { ...userData },
  });
};

export const getPatientByIdAPIRequest = async (patientData: PatientFetchByIdInterface) => {
  return await authenticatedRequest(
    `${process.env.REACT_APP_API_URL}/v1/patient/${patientData?.userId}/${patientData?.section}`,
    'get',
    {
      data: {},
    }
  );
};

// export const updateUsersAPIRequest = (userData: UserUpdateInterface) => {
//   return Axios.put('http://localhost:8081/api/v1/user/', userData);
// };

// export const deleteUsersAPIRequest = () => {
//   return Axios.delete('http://localhost:8081/api/v1/');
// };

// export const getUserByIdAPIRequest = (user: UserFetchInterface) => {
//   return Axios.get(`http://localhost:8081/api/v1/user/${user?.userId}`);
// };

export const updatePatientAPIRequest = async (patientData: PatientDemogrphicUpdateInterface) => {
  // console.log('== patientData ==', patientData);

  return await authenticatedRequest(
    `${process.env.REACT_APP_API_URL}/v1/patient/${patientData?.patientId}/demographics`,
    'put',
    {
      data: { ...patientData?.demographicData },
    }
  );
};

// TO REMOVE (by a proper API)

export const getPatientByIdForDemoAPIRequest = async (patientData: PatientFetchByIdInterface) => {
  return await authenticatedRequest(
    `${process.env.REACT_APP_API_URL}/v1/patient/${patientData?.userId}/${patientData?.section}`,
    'get',
    {
      data: {},
    }
  );
};
